import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

function RedirectSmsToOrder() {
  const { orderId } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  React.useEffect(() => {
    const param = new URLSearchParams({
      utm_source: 'twilio-eta-sms',
      utm_medium: 'sms',
      utm_campaign: 'twilio-sms',
    })

    const hasPrevParams = searchParams.size > 0
    const appendPrevParams = hasPrevParams ? `&${searchParams.toString()}` : ''

    navigate(`/orders/${orderId}?${param.toString()}${appendPrevParams}`, {
      replace: true,
    })
  }, [orderId, navigate, searchParams])

  return null
}

export default RedirectSmsToOrder
